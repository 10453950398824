





































































import { Vue, Component, Ref, Prop } from 'vue-property-decorator'
import { RegraDeEstado } from '@/models/fiscal/RegraDeEstado'
import SeletorDeEstado from '@/components/ui/SeletorDeEstado.vue'
import { sortImpostos } from '@/shareds/fiscal'
import CamposDeImposto from './shareds/CamposDeImposto.vue'
import CampoDePercentual from '@/components/ui/CampoDePercentual.vue'
import CamposDeTipoDeOperacaoFiscal from './shareds/CamposDeTipoDeOperacaoFiscal.vue'
import { RegimeFiscal } from '@/models'

@Component({
	components: {
		SeletorDeEstado,
		CamposDeImposto,
		CampoDePercentual,
		CamposDeTipoDeOperacaoFiscal,
	},
})
export default class DialogoDeRegraDeEstado extends Vue {
	@Ref() campoDeEstado!: SeletorDeEstado
	@Prop({ type: Boolean }) salvando!: boolean

	regrasDeEstado: RegraDeEstado | null = null
	regimeFiscal: RegimeFiscal | null = null
	mostra = false

	mostrar(regraDeEstado: RegraDeEstado, regimeFiscal: RegimeFiscal) {
		this.regrasDeEstado = regraDeEstado
		this.regimeFiscal = regimeFiscal
		this.mostra = true

		if (!regraDeEstado.uf)
			setTimeout(() => {
				this.campoDeEstado.focus()
			})
	}

	esconder() {
		this.mostra = false
	}

	get impostosOrdenados() {
		if (!this.regrasDeEstado) return []
		return Object.values(this.regrasDeEstado.impostos).sort(sortImpostos)
	}

	get operacoesFiscais() {
		if (!this.regrasDeEstado) return []
		return this.regrasDeEstado.tiposDeOperacoesFiscais
	}

	get isICMS60() {
		return this.regrasDeEstado?.impostos.ICMS.csts[0].cst.codigo === '60'
	}
}
