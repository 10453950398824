var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.mostra),callback:function ($$v) {_vm.mostra=$$v},expression:"mostra"}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return (function () { return _vm.$emit('salvar', _vm.regrasDeEstado); }).apply(null, arguments)}}},[(_vm.regrasDeEstado)?_c('v-card',{staticClass:"pb-1"},[_c('v-card-title',{staticClass:"pb-1"},[_vm._v("Regra de Estado")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('SeletorDeEstado',{ref:"campoDeEstado",attrs:{"label":"Estado"},model:{value:(_vm.regrasDeEstado.uf),callback:function ($$v) {_vm.$set(_vm.regrasDeEstado, "uf", $$v)},expression:"regrasDeEstado.uf"}})],1),_c('v-col',{attrs:{"cols":"12","sm":_vm.isICMS60 ? 6 : 10}},[_c('CampoDePercentual',{attrs:{"label":"FCP"},model:{value:(_vm.regrasDeEstado.percentualFcp),callback:function ($$v) {_vm.$set(_vm.regrasDeEstado, "percentualFcp", $$v)},expression:"regrasDeEstado.percentualFcp"}})],1),(_vm.isICMS60)?_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('CampoDePercentual',{attrs:{"label":"MVA","persistent-hint":"","hint":"Margem de Valor Agregado"},model:{value:(_vm.regrasDeEstado.percentualMVA),callback:function ($$v) {_vm.$set(_vm.regrasDeEstado, "percentualMVA", $$v)},expression:"regrasDeEstado.percentualMVA"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('header',[_vm._v("Impostos")]),_vm._l((_vm.impostosOrdenados),function(imposto){return _c('CamposDeImposto',{key:'campo-' + imposto.tipoDeImposto,attrs:{"value":imposto,"regimeFiscal":_vm.regimeFiscal,"fixo":""},on:{"input":function (imposto) {
											var _obj;

											return _vm.regrasDeEstado
											? (_vm.regrasDeEstado.impostos = Object.assign({}, _vm.regrasDeEstado.impostos,
													( _obj = {}, _obj[imposto.nome] = imposto, _obj )))
											: undefined;
}}})})],2),_c('v-col',{attrs:{"cols":"12"}},[_c('header',[_vm._v("Operações")]),_c('CamposDeTipoDeOperacaoFiscal',{model:{value:(_vm.regrasDeEstado),callback:function ($$v) {_vm.regrasDeEstado=$$v},expression:"regrasDeEstado"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"type":"button","text":"","disabled":_vm.salvando},on:{"click":_vm.esconder}},[_vm._v("Cancelar")]),_c('v-btn',{staticClass:"ml-auto",attrs:{"type":"submit","text":"","color":"success","disabled":_vm.salvando,"loading":_vm.salvando}},[_vm._v("Salvar")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }