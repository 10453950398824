


















































































































import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import { RegraDeImposto, CapaRegraImposto, RegraDeAplicacao } from '@/models'
import SeletorDeOrigem from '@/components/fiscal/SeletorDeOrigem.vue'
import SeletorDeRegimeFiscal from '@/components/fiscal/SeletorDeRegimeFiscal.vue'
import CamposDeImposto from './shareds/CamposDeImposto.vue'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import EdicaoGenerica from '@/components/layout/EdicaoGenerica.vue'
import DialogoDeRegraDeEstado from './DialogoDeRegraDeEstado.vue'
import DialogoDeRegraDeAplicacao from './DialogoDeRegraDeAplicacao.vue'
import { nextTick } from '@/shareds/utils'
import {
	SaveRegraDeImpostoUseCase,
	FindOrigemUseCase,
	FindIPIUseCase,
	FindRegraDeImpostoUseCase, 
	DeleteRegraDeAplicacaoUseCase,
	FindRegraDeAplicacaoUseCase,
	SaveRegraDeAplicacaoUseCase,
} from '@/usecases'
import { obrigatorio } from '@/shareds/regras-de-form'
import axios, { CancelTokenSource } from 'axios'
import { DataOptions } from 'vuetify'
import DataTableDeCrud from '@/components/ui/DataTableDeCrud.vue'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import DialogoDeCapaDeRegraFiscal from '@/views/application/configuracoes/fiscal/DialogoDeCapaDeRegraFiscal.vue'
import { sortConverter } from '@/shareds/paginacao'

@Component({
	components: {
		SeletorDeOrigem,
		SeletorDeRegimeFiscal,
		EdicaoGenerica,
		DataTableDeCrud,
		DialogoDeRegraDeEstado,
		DialogoDeRegraDeAplicacao,
		CamposDeImposto,
		Confirmacao,
		DialogoDeCapaDeRegraFiscal,
	},
})
export default class TelaDeEdicaoDeRegraDeImposto extends Vue {
	@Prop({ type: String }) id!: string | undefined
	@Prop({ type: Boolean }) novo!: boolean
	@Ref() dialogo!: DialogoDeRegraDeAplicacao
	@Ref() edicaoGenerica!: EdicaoGenerica<CapaRegraImposto>
	@Ref() dataTablePaginado!: DataTableDeCrud
	@Ref() confirmacaoDeDeletarEstado!: Confirmacao
	@Ref() dialogoDeEdicao!: DialogoDeCapaDeRegraFiscal

	obrigatorio = obrigatorio

	salvandoRegraDeAplicacao = false

	headers = [
		{ text: 'Regra de Aplicação', value: 'descricao' },
	]

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	findRegraDeImpostoUseCase = new FindRegraDeImpostoUseCase()
	saveRegraDeImpostoUseCase = new SaveRegraDeImpostoUseCase()
	deleteRegraDeAplicacaoUsecase = new DeleteRegraDeAplicacaoUseCase()
	findOrigemUsecase = new FindOrigemUseCase()
	findRegraDeAplicacaoUseCase = new FindRegraDeAplicacaoUseCase()
	saveRegraDeAplicacaoUseCase = new SaveRegraDeAplicacaoUseCase()

	regra: CapaRegraImposto | null = null
	versaoAnterior: CapaRegraImposto | null = null
	cancelToken: CancelTokenSource | null = null
	totalDeRegistros = -1
	carregando = true
	loadingItens = false
	salvando = false
	salvo = false
	regrasDeAplicacao: RegraDeAplicacao[] = []

	regraSelecionada = ''
	removerRegraAplicacao = false

	erro: null | Error = null

	created() {
		this.carregar()
	}

	voltarParaTelaAnterior() {
		this.$router.back();
	}

	async carregar() {
		try {
			this.carregando = true
			this.regra =
				this.novo || !this.id
					? await criarRegraDeImposto()
					: await this.findRegraDeImpostoUseCase.getRegra(this.id)
			nextTick().then(() => {
				this.versaoAnterior = this.versaoAnterior = JSON.parse(JSON.stringify(this.regra))
			})
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	editarCapa() {
		if (!this.regra?.id) return
		this.dialogoDeEdicao.mostrar(this.regra)
	}

	async buscarRegraDeAplicacao() {
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			if (!this.id) return
			this.loadingItens = true
			this.cancelToken = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}
			const sorted = sortConverter(this.paginacao)
			const pageItens = await this.findRegraDeAplicacaoUseCase
				.findByRegraImposto(this.id,{
					sort: [...sorted],
					page: this.paginacao.page - 1 || 0,
					size: this.paginacao.itemsPerPage,
				}, axiosConfig)

			this.regrasDeAplicacao = pageItens.content
			this.totalDeRegistros = pageItens.totalElements
		} catch (e) {
			if (axios.isCancel(e)) return
			AlertModule.setError(e)
		} finally {
			this.loadingItens = false
		}
	}

	async abrirDialogo(indice: number) {
		if (!this.regra) return
		this.dialogo.mostrar(
			this.regrasDeAplicacao[indice] || (await criarRegraDeAplicacao()),
			this.regra.regimeFiscal || 'Simples Nacional',
		)
	}

	async removerRegra(indice: number) {
		this.regraSelecionada = this.regrasDeAplicacao[indice].descricao

		try {
			if (!this.regra || !this.regrasDeAplicacao[indice]) return
			const itemId = this.regrasDeAplicacao[indice].id
			if (itemId) {
				await this.deleteRegraDeAplicacaoUsecase.delete(itemId)
			}
			this.regrasDeAplicacao.splice(indice, 1)
		} catch (e) {
			AlertModule.setError(e)
		}
	}

	async salvarRegraDeAplicacao(regraDeAplicacao: RegraDeAplicacao) {
		if (!this.id) return
		const indice = this.regrasDeAplicacao.findIndex(
			({ id }) => id === regraDeAplicacao.id,
		)

		try {
			this.salvandoRegraDeAplicacao = true
			if (indice !== -1 && regraDeAplicacao.id) {
				const item = await this.saveRegraDeAplicacaoUseCase.update(
					regraDeAplicacao,
				)
				this.regrasDeAplicacao.splice(indice, 1, item)
				this.dialogo.esconder()
				AlertModule.setSuccess(`${item.descricao} foi alterado com sucesso`)
			} else {
				const item = await this.saveRegraDeImpostoUseCase.addItem(
					this.id,
					regraDeAplicacao,
				)
				this.dialogo.esconder()
				this.regrasDeAplicacao.push(item)
				++this.totalDeRegistros
				AlertModule.setSuccess(`${item.descricao} foi criado com sucesso`)
			}
		} catch (e) {
			AlertModule.setError(e)
		} finally {
			this.salvandoRegraDeAplicacao = false
		}
		
	}

	@Watch('paginacao', { deep: true })
	onChangePaginacao = this.buscarRegraDeAplicacao

	getOperacao(operacao: string) {
		return operacao === 'DEVOLUCAO'
			? 'Devolução'
			: 'Saída'
	}
}

async function criarRegraDeImposto(): Promise<RegraDeImposto> {
	const findOrigemUsecase = new FindOrigemUseCase()
	const findIPIUseCase = new FindIPIUseCase()
	const [origemDefault, cstIPIDefault] = await Promise.all([
		(await findOrigemUsecase.find({ codigo: '0' })).content[0],
		(await findIPIUseCase.find({ codigo: '51' })).content[0],
	])

	return {
		descricao: '',
		origem: {
			...origemDefault,
		},
		ipi: {
			tipoDeImposto: 'IPI',
			aliquota: 0,
			csts: [
				{
					id: '',
					operacao: 'SAIDA',
					cst: {
						...cstIPIDefault,
						tipo: 'IPI',
					},
				},
				{
					id: '',
					operacao: 'DEVOLUCAO',
					cst: {
						...cstIPIDefault,
						tipo: 'IPI',
					},
				},
			],
		},
		regimeFiscal: 'Simples Nacional',
		regrasDeAplicacao: [],
	}
}

async function criarRegraDeAplicacao(): Promise<RegraDeAplicacao> {
	return {
		descricao: '',
		tipoDeCliente: 'Contribuinte',
		regrasPorEstado: [],
	}
}
